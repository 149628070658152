import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/proyecto/logo/logo.png";
import wp from "../assets/img/proyecto/wp.png";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import styled from "styled-components";

import background from "../assets/img/home/home0_1800x800.jpg";
import home from "../assets/img/proyecto/body/body.jpg";
import civil from "../assets/img/proyecto/body/civil.png";
import empresarial from "../assets/img/proyecto/body/empresarial.png";
import extranjeria from "../assets/img/proyecto/body/extranjeria.png";
import familia from "../assets/img/proyecto/body/familia.png";
import gestion from "../assets/img/proyecto/body/gestion.png";
import internacional from "../assets/img/proyecto/body/internacional.png";
import laboral from "../assets/img/proyecto/body/laboral.png";
import notario from "../assets/img/proyecto/body/notario.png";
import penal from "../assets/img/proyecto/body/penal.png";

export default function Body({acceso}) {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const irWP = (event) => {
    window.location.href = "https://wa.me/34605893451";
  }

  return (
    <>
      <BodyContainer>
        <body>
          <div
            className="col-sm-12 col-md-12 fl-page-logo-wrap"
            id="yui_3_18_1_1_1719501602214_1157"
          >
            <div
              id="fl-main-content"
              className="fl-page-content"
              itemProp="mainContentOfPage"
              role="main"
            >
              <div
                className="fl-content-full container"
                id="yui_3_18_1_1_1719501602214_1533"
              >
                <div className="row" id="yui_3_18_1_1_1719501602214_1532">
                  <div
                    className="fl-content col-md-12"
                    id="yui_3_18_1_1_1719501602214_1531"
                  >
                    <div
                      className="fl-post post-2 page type-page status-publish hentry"
                      id="fl-post-2"
                      itemScope="itemscope"
                      itemType="https://schema.org/CreativeWork"
                    >
                      <div
                        className="fl-post-content clearfix"
                        itemProp="text"
                        id="yui_3_18_1_1_1719501602214_1530"
                      >
                        <div
                          className="fl-builder-content fl-builder-content-2 fl-builder-content-primary fl-builder-global-templates-locked"
                          data-post-id={2}
                          id="yui_3_18_1_1_1719501602214_1529"
                        >

                          <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <ol className="carousel-indicators"></ol>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  className="d-block w-100"
                                  src={home}
                                  alt="First slide"
                                />
                              </div>
                            </div>
                          </div>


                          <div
                            className="fl-row fl-row-full-width fl-row-bg-photo fl-node-6092c7848ba9d fl-row-default-height fl-row-align-center fl-row-bg-overlay fl-row-bg-fixed"
                            data-node="6092c7848ba9d"
                          >
                            <div className="fl-row-content-wrap">
                              <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                <div
                                  className="fl-col-group fl-node-6092cabcec94c fl-col-group-equal-height fl-col-group-align-center"
                                  data-node="6092cabcec94c"
                                >
                                  <div
                                    className="fl-col fl-node-6092cabceca4d fl-col-small"
                                    data-node="6092cabceca4d"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-rich-text fl-node-6092d40dc8ecf"
                                        data-node="6092d40dc8ecf"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="fl-rich-text">
                                            <h2>{t("body.texto1")}</h2>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-module fl-module-rich-text fl-node-6092cb0a4abd3"
                                        data-node="6092cb0a4abd3"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="fl-rich-text">
                                            <h3>{t("body.texto2")}</h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="fl-col fl-node-6092cabceca50 fl-visible-desktop fl-visible-large fl-visible-medium"
                                    data-node="6092cabceca50"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-slideshow fl-node-609423f233daa fl-visible-desktop fl-visible-large fl-visible-medium fl-animation fl-fade-right fl-animated"
                                        data-node="609423f233daa"
                                        data-animation-delay={0}
                                        data-animation-duration={1}
                                        style={{ animationDuration: "1s" }}
                                      >
                                        <div
                                          id="carouselExampleIndicators"
                                          className="carousel slide"
                                          data-ride="carousel"
                                        >
                                          <ol className="carousel-indicators"></ol>
                                          <div className="carousel-inner">
                                            <div className="carousel-item active">
                                              <img
                                                className="d-block w-100"
                                                src={background}
                                                alt="First slide"
                                              />
                                              <div className="carousel-caption d-none d-md-block"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br /><br />
                          <section className="content">
                            <div className="container-fluid">
                              {/* Info boxes */}

                              <div className="info-box mb-3">
                                    <span className="info-box-icon azulTonalidad elevation-1"><font color="#FFFFFF"><i className="bi bi-file-earmark-text"></i></font></span>
                                    <div className="info-box-content">
                                      <h2><span className="info-box-number"><font color="#001952">{t("seo.texto1")}</font></span></h2>
                                      <h4><span className=""><font color="#001952">{t("seo.texto3")}</font></span></h4>
                                    </div>
                                    {/* /.info-box-content */}
                                  </div>
                                  <div className="info-box mb-3">
                                    <span className="info-box-icon azulTonalidad elevation-1"><font color="#FFFFFF"><i className="bi bi-mortarboard-fill"></i></font></span>
                                    <div className="info-box-content">
                                      <h2><span className="info-box-number"><font color="#001952">{t("seo.texto2")}</font></span></h2>
                                      <h4><span className=""><font color="#001952">{t("seo.texto4")}</font></span></h4>
                                    </div>
                                    {/* /.info-box-content */}
                                  </div>

                                  <div className="card-footer text-center ">
                                    <button onClick={irWP} className=" btn_transparent btn color-blanco wp" style={{marginRight: 5}} type="submit">
                                       <font color="#001952" size="5"><strong><u>{t("body.texto17")}</u></strong></font>
                                    </button>
                                  </div>

                            </div>{/*/. container-fluid */}
                          </section>

                          <main><br /><br /><br /><br /><br />
          {/* Wrap the rest of the page in another container to center all the content. */}
          <div className="container marketing">
            {/* Three columns of text below the carousel */}
            <div className="row">
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={civil} />
                <h2 className="fw-normal">{t("extranjeria.texto1")}</h2>
                <NavLink to="/civil">
                  <p><a className="btn colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                  </NavLink>
                  <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={empresarial} />
                <h2 className="fw-normal">{t("extranjeria.texto3")}</h2>
                <NavLink to="/empresarial">
                <p><a className="btn  colordiv colortext">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={extranjeria} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/extranjeria">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={familia} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/familia">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={gestion} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/gestor">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={internacional} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/internacional">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={laboral} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/laboral">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={notario} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/notario">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={penal} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/penal">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
              </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </main>          


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </BodyContainer>
    </>
  );
}

const BodyContainer = styled.body`
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }

background-color: #343a40;
  h1 {
    color: #f3eded;
  }
  h3 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
  .azulTonalidad {
      background-color: #001952;
  }
  .BlancoTonalidad {
      background-color: #ffffff;
  }

`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
